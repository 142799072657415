body {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    background-color: #f9f9f9;
  }
  
  .top-nav {
    display: flex;
    align-items: center;
    justify-content: space-around; /* Makes sure elements are spaced evenly */
    padding: 20px;
    background: linear-gradient(90deg, #30302f 1%, #a6192e);
    color: white;
    padding-left: 50px;
    padding-right: 50px;
  }

  /* Burger menu styles */
.burger-menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    display: none; /* Hide on desktop */
  }

  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 1380px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .top-nav-left,
  .nav-right {
    display: flex;
    align-items: center;
    flex: 1; /* Allows equal spacing between left and right content */
    justify-content: center; /* Centers the content in each flex box */
  }
  
  .logo {
    display: flex;
    align-items: center;
    /* margin-right: auto;  Pushes the logo to the left */
  }
  
  .nav-links {
    display: flex;
    gap: 30px;
  }

  .nav-mobile-links {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .nav-mobile-links.show {
    display: flex;
  }
  
  .nav-url {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  
  .logo img {
    height: 46px;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
    margin-left: 20px;
  }

  .nav-url {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  
  .nav-right {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .help-link {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  .help-icon {
    width: 20px;
    height: 20px;
    fill: white;
  }
  
  .breadcrumb {
    padding: 15px;
    font-size: 0.9rem;
    color: #a32132;
  }
  
  .header-section {
    text-align: center;
    background: linear-gradient(to left, #b72b35, #000000);
    /* background: url('../assets/legal-and-policies.png'); */
    color: white;
    padding: 60px;
  }

  .hero-text {
    font-size: 54px;
    font-weight: 600;
  }
  
  .gift-description {
    text-align: left;
    padding: 40px;
    background: #f5f5f5;
  }
  
  .gift-description h2 {
    color: #a32132;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 2rem;
    font-weight: bolder;
  }
  
  .gift-description p {
    margin: 10 auto;
    font-size: 1.2rem;
    color: #555;
    font-weight: bold;
  }
  
  .steps-navigation {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    background-color: #f5f5f5;
    padding: 15px 0;
    border-radius: 8px;
  }
  
  .step {
    padding: 10px 30px;
    border-radius: 50px;
    margin: 0 10px;
    color: #777;
    background-color: #e6e6e6;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .step-number {
    background: #a32132;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 0.9rem;
  }
  
  .step.active {
    background-color: #a32132;
    color: #fff;
  }
  
  .voucher-form {
    max-width: 1100px;
    margin: 30px auto;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }
  
  .input-label {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 10px;
    display: block;
  }
  
  .voucher-type {
    margin-bottom: 25px;
  }

  .content {
    padding: 20px;
  }
  
  .voucher-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .carousel-button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #a32132;
  }
  
  .voucher-card {
    text-align: center;
    padding: 10px;
  }
  
  .voucher-card img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .voucher-type-text {
    margin-top: 10px;
    font-weight: bold;
    color: #333;
  }
  
  .custom-message {
    margin-bottom: 25px;
    margin-right: 10px;
  }
  
  .custom-message textarea {
    width: 100%;
    font-size: 1rem;
    resize: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-family: 'DM Sans', sans-serif;
    padding-top: 10px;
    padding-left: 10px;
  }

  .custom-message textarea::placeholder {
    font-family: 'DM Sans', sans-serif;
    color: #aaa;
  }
  
  .character-count {
    text-align: right;
    font-size: 0.9rem;
    color: #777;
    margin-top: 5px;
  }
  
  .voucher-value {
    margin-bottom: 25px;
  }

  .currency-value-selectors {
    display: flex;
    gap: 10px;
  }
  
  .currency-selector, .value-selector {
    flex: 1;
  }
  
  .currency-selector select, .value-selector select {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .input-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .input-field {
    flex: 1;
    padding: 15px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }

  .input-prefix {
    flex: 0.3;
  }

  .toggle-checkbox {
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 40px;
    height: 20px;
    background: #ccc;
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
  }

  .toggle-checkbox:checked {
    background: #a32132;
  }

  .toggle-checkbox:checked::before {
    transform: translateX(20px);
  }

  .toggle-checkbox::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  .toggle-label {
    font-size: 1rem;
    color: #333;
  }

  

  /* Center the content for step 4 */
  .payment-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }

  .payment-section h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  .payment-section .summary-left {
    margin-top: 15px;
    font-size: 1.2rem;
    color: #555;
    max-width: 600px; /* Adjust the width to control the max space used by the text */
  }

  
  .submit-button {
    padding: 2% 10%;
    background-color: #a32132;
    color: #fff;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'DM Sans', sans-serif;
  }
  
  .submit-button:hover {
    background-color: #600d17;
  }

  .submit-button.continue-right {
    margin-left: auto;
  }

  .back-button {
    padding: 15px 30px;
    background-color: transparent;
    color: #a32132;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
    font-family: 'DM Sans', sans-serif;
  }
  
  .back-button:hover {
    color: #600d17;
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .form-checkbox {
    accent-color: #a32132;
    height: 20px;
    width: 20px;
  }

  .checkbox-group {
    margin-bottom: 20px;
  }

  .inline-url {
    color: #a32132;
    font-weight: bold;
  }

  .summary-section {
    display: flex;
  }

  .summary-left {
    flex: 1;
  }

  .summary-right {
    margin-right: 60px;
  }

  .terms-and-conditions {
    margin-top: 20px;
    font-size: 1rem;
    color: #333;
  }

  .footer {
    background-color: #333;
    color: #fff;
    padding: 40px 20px;
    font-size: 0.9rem;
    margin-top: auto 0;
    bottom: 0;
  }
  
  .newsletter {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .newsletter-input {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .newsletter-input input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
  }
  
  .newsletter-input button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    cursor: pointer;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .footer-links a {
    text-decoration: none;
    color: white;
  }
  
  .footer-column {
    width: 20%;
  }
  
  .footer-column h4 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 10px;
  }
  
  .footer-apps {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .footer-apps img {
    width: 150px;
  }
  
  .social-media {
    text-align: center;
    margin-top: 40px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
  }
  
  .footer-bottom {
    text-align: right;
    margin-top: 30px;
  }
  
  @media (max-width: 768px) {

    .nav-container {
      display: block;
      justify-content:left;
      margin-left: -3rem;
    }

    .burger-div {
        float: right;
        margin-left: 6rem;
    }

    .burger-menu-button {
        display: block; /* Show burger menu button on mobile */
        /* margin-left: 50%; */
      }

    .burger-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .modal-content {
        background: #ffffff;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;
      }

    .mobile-nav-separator {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mobile-urls {
        margin-top: 180px;
        width: 100%;
        height: 100%;
    }
      
    .mobile-urls a {
        color: #a32132;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 100;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 10px;
        padding: 5%;
        text-align: left;
      }
      
      .close-modal-button {
        background: none;
        border: none;
        color: black;
        font-size: 2rem;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
    
      .nav-right .help-link {
        display: none; /* Hide the help link on mobile since it will be in the burger menu */
      }

      .home-link {
        display: none; /* Hide the help link on mobile since it will be in the burger menu */
      }

      .toggle-label {
        padding-bottom: 10px;
      }

      .checkbox-group {
        margin-bottom: 20px;
      }

      .input-field {
        max-width: max-content;
        margin-bottom: 10px;
        min-width: 270px;
      }

      .input-group {
        display: inline-block;
        min-width: min-content;
        width: 100%;
      }

      .logo {
        margin: auto;
        padding: 0%;
      }

      .logo img {
        height: 36px;

      }
    
      .burger-menu {
        display: flex;
        flex-direction: column;
      }

    .nav-mobile-links {
        display: none; /* Hide by default */
        flex-direction: column;
        background-color: #a6192e;
        position: absolute;
        top: 60px;
        right: 20px;
        padding: 10px;
        border-radius: 8px;
    }

    .nav-mobile-links a {
        color: white;
        text-decoration: none;
        margin-bottom: 10px;
      }

    .nav-mobile-links.show {
        display: flex; /* Show when burger menu is open */
      }

    .currency-value-selectors {
      flex-direction: column;
    }
  
    .voucher-form {
      padding: 20px;
    }
  
    .steps-navigation {
      flex-direction: column;
    }
  
    .step {
      margin-bottom: 10px;
    }
  
    .voucher-carousel {
      flex-direction: column;
    }
  
    .carousel-button {
      font-size: 1.5rem;
    }
  
    .voucher-card img {
      max-width: 250px;
    }

    .summary-section {
      flex-direction: column;
    }
  
    .footer-links {
      flex-direction: column;
      gap: 20px;
    }
  
    .footer-column {
      width: 100%;
    }
  }